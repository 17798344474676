import React from "react";
import { useNavigate, useLocation } from "react-router";
import { userRoles } from "../services/constant";
import { clearLocalStorage, getUserDetails } from "../services/userStorage";
import { useContext } from "react";
import UserContext from "../components/UserContext";
import "./AppMenu.css";

function AppMenu() {
  const navigate = useNavigate();
  const location = useLocation(); // To track the active route
  const userContext = useContext(UserContext);
  const userDetails = getUserDetails();

  const getRole = (role) => {
    let roleData = userRoles.find((item) => item.value === role);
    return roleData ? roleData.name : "";
  };

  // Check if the current route is active
  const isActive = (path) => location.pathname === path;

  return (
    <div className="menu-container">
      <div className="menu-items">
        <button
          className={`menu-button ${isActive("/dashboard") ? "active" : ""}`}
          onClick={() => navigate("/dashboard")}
        >
          Dashboard
        </button>
        <button
          className={`menu-button ${isActive("/home") ? "active" : ""}`}
          onClick={() => navigate("/home")}
        >
          Home
        </button>
        <button
          className={`menu-button ${isActive("/site") ? "active" : ""}`}
          onClick={() => navigate("/site")}
        >
          Sites
        </button>
        <button
          className={`menu-button ${isActive("/details") ? "active" : ""}`}
          onClick={() => navigate("/details")}
        >
          Details
        </button>
        <button
          className={`menu-button ${isActive("/support") ? "active" : ""}`}
          onClick={() => navigate("/support")}
        >
          Support
        </button>
        <button
          className={`menu-button ${isActive("/errors") ? "active" : ""}`}
          onClick={() => navigate("/errors")}
        >
          Errors
        </button>
        <button
          className={`menu-button ${isActive("/memory") ? "active" : ""}`}
          onClick={() => navigate("/memory")}
        >
          Memory
        </button>
        <button
          className={`menu-button ${isActive("/todo") ? "active" : ""}`}
          onClick={() => navigate("/todo")}
        >
          Todo
        </button>
        <button
          className={`menu-button ${isActive("/process") ? "active" : ""}`}
          onClick={() => navigate("/process")}
        >
          Processes
        </button>
        <button
          className={`menu-button ${isActive("/release") ? "active" : ""}`}
          onClick={() => navigate("/release")}
        >
          Releases
        </button>

        {userDetails && userDetails.role <= 2 && (
          <button
            className={`menu-button ${isActive("/user") ? "active" : ""}`}
            onClick={() => navigate("/user")}
          >
            Users
          </button>
        )}

        <button
          className="menu-button logout-button"
          onClick={() => {
            clearLocalStorage();
            userContext.doLogin();
            navigate("/login");
          }}
        >
          Logout
        </button>

        {userDetails && (
          <span className="user-details">
            {userDetails.name} ({getRole(userDetails.role)})
          </span>
        )}
      </div>
    </div>
  );
}

export default AppMenu;
