import React, { useEffect, useState } from 'react';
import RecordListTable from './RecordListTable';
import { useNavigate, useParams, useLocation } from "react-router";
import { title } from "../config";
import { deleteProcessRecord, getProcessRecords, getProcessRecordsByProcessId } from '../services/processRecordAPI';
import AppMenu from '../components/AppMenu';
import { Input } from 'reactstrap';
import { deleteQARecord, getQARecordsByProcessId } from '../services/qaRecordAPI';

export default function Records() {
    const { state } = useLocation();
    const navigate = useNavigate();

    const process = state.process;
    const [localRecords, setLocalRecords] = useState([]);
    const [filteredRecords, setFilteredRecords] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchRecords();
    }, []);

    useEffect(() => {
        filterRecords();
    }, [searchQuery, localRecords]);

    const fetchRecords = async () => {
        const records = state?.qaRecords === true ? await getQARecordsByProcessId(process?._id) : await getProcessRecordsByProcessId(process?._id); // Fetch all records once
        setLocalRecords(records);
        setLoading(false);
    };

    const filterRecords = () => {
        const query = searchQuery.toLowerCase();
        if (!query) {
            setFilteredRecords(localRecords);
        } else {
            const filtered = localRecords.filter(record => {
                return record?.record?.processName?.toLowerCase().includes(query) ||
                    record?.record?.siteName?.toLowerCase().includes(query) ||
                    record?.record?.createdBy?.toLowerCase().includes(query) ||
                    record?.record?.lastUpdated?.toLowerCase().includes(query);

            });
            setFilteredRecords(filtered);
        }
    };

    const handleDelete = async (id) => {
        state?.qaRecords === true ? await deleteQARecord(id) : await deleteProcessRecord(id);
        fetchRecords();
    };

    const handleEdit = (id) => {
        state?.qaRecords === true ? navigate(`/process/qa-records/${process?._id}/edit/${id}`, { state: { id, qaRecords: true } }) : navigate(`/process/records/${process?._id}/edit/${id}`, { state: { id } });
    };

    return (
        <div>
            <div className="px-2" style={{backgroundColor:"#f8f9fa"}}>
                <h3 className="m-0 p-2">{title} | {process?.processName}</h3>
                <AppMenu />
            </div>
            <div className="p-0 px-2 m-0">
                <div className="px-2 col-2">
                    <Input
                        type="text"
                        placeholder="Search Records"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </div>
                <hr className="p-0 my-2" />
            </div>
            {
                loading ?
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "75vh" }}>
                        <div className="spinner-border">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div> :
                    filteredRecords.length > 0 ? (
                        <RecordListTable
                            items={filteredRecords}
                            doDelete={handleDelete}
                            doEdit={handleEdit}
                        />
                    )
                        :
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "75vh" }}>
                            <span className="">No records found...</span>
                        </div>}
        </div>
    );
}
