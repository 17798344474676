import React, { useEffect, useState } from 'react'
import { title } from "../config";
import AppMenu from '../components/AppMenu';
import { useNavigate } from "react-router";
import { Table } from 'reactstrap';
import moment from 'moment';
import { deleteRelease, getReleaseData } from '../services/releaseAPI';
import { deleteReleaseRecord, getReleaseRecords, getReleaseRecordsByReleaseId } from '../services/releaseRecordAPI';

const Release = () => {
    const [tableData, setTableData] = useState([]);
    const [releaseData, setReleaseData] = useState([]);
    useEffect(() => {
        reloadRelease();
        getReleaseID();
    }, []);
    const reloadRelease = async () => {
        let records = await getReleaseData();
        setTableData(records);
    };
    const getReleaseID = async () => {
        const data = await getReleaseRecords()
        setReleaseData(data)
    }
    const navigate = useNavigate();
    const handleAdd = (id, data) => {
        const release = releaseData.find((item) => item.record.releaseId === id);
        if (!release) {
            navigate(`/release/${id}/add`, { state: { release: data } });
        } else{
            alert("Record already exist")
        }   
    }
    const handleEdit = (id) => {
        const release = releaseData.find((item) => item.record.releaseId === id);
        if (release) {
            const releaseId = release._id;
            navigate(`/release/${id}/edit/${releaseId}`, { state: { id } });
        } else {
            console.error("Release ID not found");
            alert("There is no record to edit")
        }
    };    
    const doEdit = (id) => {
        navigate(`/release/edit/${id}`)
    };
    const doDelete = async (id) => {
        const release = releaseData.find((item) => item.record.releaseId === id);
        await deleteRelease(id);
        if (release) {
            const releaseId = release._id;
            await deleteReleaseRecord(releaseId);
        } 
        reloadRelease();
    };

    return (
        <div>
            <div className="px-2" style={{backgroundColor:"#f8f9fa"}}>
            <h3 className="title m-0 p-2">Remote<span style={{color: '#ff6501'}}>Xs</span> Monitor | Release</h3>
            <AppMenu />
                <div className="p-0 px-2 m-0">
                    <div className="px-2">
                        <span className="headerMargin ms-1"><button
                            className="fa-sharp fa-plus btn btn-outline-primary"
                            onClick={() => {
                                navigate("/release/add");
                            }}
                            variant="contained"
                        > Add Release Planning </button></span>
                        <span style={{ float: "right", marginRight: "10px" }}>
                        </span>
                    </div>
                    <hr className="p-0 my-2" />
                </div>
            </div>
            {tableData.length > 0 ? (
                <Table className="table table-hover mt-4" responsive hover>
                    <thead>
                        <tr>
                            <th>Release Name</th>
                            <th>Version</th>
                            <th>Features</th>
                            <th>Created By</th>
                            <th>Updated By</th>
                            <th>Updated At</th>
                            <th>Date</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.map((data, index) => (
                            <tr key={index}>
                                <td>{data.releaseName}</td>
                                <td>{data.version}</td>
                                <td>
                                    <div
                                        className="alert-link fa-solid fa-plus-square text-success"
                                        onClick={() => handleAdd(data?._id, data)}
                                        style={{ cursor: "pointer", fontSize: "24px", margin: "0 10px" }}
                                    />
                                    <i style={{ cursor: "pointer", fontSize: "24px", margin: "0 10px" }}
                                        className="fa fa-pencil-square text-success"
                                        onClick={() => handleEdit(data?._id)}
                                    ></i>
                                </td>
                                <td>{data.createdBy?.name}</td>
                                <td>{data.updatedBy?.name}</td>
                                <td>{moment(data.updatedAt).format("DD/MM/YYYY")}</td>
                                <td>{moment(data.date).format("DD/MM/YYYY")}</td>
                                <td className="d-flex">
                                    <i
                                        className="fas fa-edit btn btn-primary m-2"
                                        onClick={() => doEdit(data._id)}
                                        aria-hidden="true"
                                    />
                                    <i
                                        className="fa-solid fa-trash-can btn btn-danger m-2"
                                        onClick={() => doDelete(data._id)}
                                        aria-hidden="true"
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            ) : (
                <div className="text-center mt-4">
                    <h4>No records found.</h4>
                </div>
            )}
        </div>
    )
}

export default Release;
