import React, { useEffect, useState } from "react";
import AppMenu from "../components/AppMenu";
import { Table } from "reactstrap";
import { getStatesById, getStateData } from "../services/stateAPI";
import moment from "moment";
import { useParams } from "react-router";
import { title } from "../config";

export function State() {
  const [items, setItems] = useState([]);
  let { id } = useParams();
  useEffect(() => {
    reloadSite(id);
  }, [id]);
  const reloadSite = async (id) => {
    if (id !== undefined) {
      setItems(await getStatesById(id));
    } else {
      setItems(await getStateData());
    }
  };

  return (
    <div className="px-2" style={{backgroundColor:"#f8f9fa"}}>
        <h3 className="title m-0 p-2">Remote<span style={{color: '#ff6501'}}>Xs</span> Monitor | States</h3>
        <AppMenu title="States" />
      <StateList items={items} />
    </div>
  );
}
const outLogURL = (domain) => {
  const url = `https://core.${domain}/out-log`;
  return url;
};
const errorLogURL = (domain) => {
  const url = `https://core.${domain}/error-log`;
  return url;
};
const getFreeSpace = (free, size) => {
  const percentageFull = ((size - free) / size) * 100;
  const roundValue = Math.round(percentageFull);
  return roundValue;
};
const StateList = ({ items }) => {
  const [localItems, setlocalItems] = useState([...items]);
  const [toggleSorting, setToggleSorting] = useState(0);
  useEffect(() => {
    setlocalItems(items);
  }, [items]);

  const sortTable = (field) => {
    let sortedData = [];
    if (toggleSorting == 1) {
      sortedData = [...localItems].sort((a, b) => {
        if (a[field] > b[field]) {
          return -1;
        } else if (a[field] < b[field]) {
          return 1;
        } else {
          return 0;
        }
      });
      setToggleSorting(0);
    } else {
      sortedData = [...localItems].sort((a, b) => {
        if (a[field] < b[field]) {
          return -1;
        } else if (a[field] > b[field]) {
          return 1;
        } else {
          return 0;
        }
      });
      setToggleSorting(1);
    }
    setlocalItems(sortedData);
  };

  return (
    <Table className="table table-hover" responsive hover>
      <thead>
        <tr>
          <th onClick={() => sortTable("name")}>Name</th>
          <th onClick={() => sortTable("version")}>Versioon</th>
          <th onClick={() => sortTable("cms")}>CMS Status</th>
          <th onClick={() => sortTable("status")}>Proxy Status</th>
          <th onClick={() => sortTable("time")}>Time </th>
          <th onClick={() => sortTable("cpuLoad")}>CPU-Usage </th>
          <th onClick={() => sortTable("free")}>Memory</th>
          <th >P-Memory </th>
          <th>P-CPU </th>
          <th >PM2 Error </th>
          <th>PM2 Out</th>
          <th>Out Log</th>
          <th>Error Log</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {localItems.map((item) => (
          <tr key={item._id}>
            <td>{item.name} </td>
            <td>{item.version} </td>
            <td>{item.cms} </td>
            <td>{item.status} </td>
            <td>
              {moment(item.time)
                .zone("Asia/Kolkata")
                .format("hh:mm A - DD/MM/YYYY")}
            </td>
            <td>{item.cpuLoad ? item.cpuLoad.join(" / ") : "-"}</td>
            <td>
              {`${item.free
                ? getFreeSpace(item.free, item.size) +
                "%" +
                " (" +
                (item.size / 1073741824).toFixed(0) +
                "GB)"
                : "-"
                }`}
              {/*              {item.freeMem} / {item.totalMem}*/}
            </td>
            <td>{item.monit ? (item?.monit?.memory / 1000000).toFixed(2) : "-"}</td>
            <td>{item?.monit?.cpu || "-"}</td>
            {/* <td>{item.pm2ErrSize}</td> */}
            <td>{item.pm2ErrSize ?
              (item?.pm2ErrSize / (1024 ** 2))?.toFixed(2) + 'MB' : "-"}</td>
            <td>
              {item.pm2OutSize ?
                (item?.pm2OutSize / (1024 ** 2))?.toFixed(2) + 'MB' : "-"}
            </td>
            <td>
              <a href={outLogURL(item.domain)}>
                <i
                  className="fa-solid fa-gears btn btn-success"
                  aria-hidden="true"
                ></i>
              </a>
            </td>

            <td>
              <a href={errorLogURL(item.domain)}>
                <i
                  className="fa-solid fa-circle-exclamation btn btn-danger"
                  aria-hidden="true"
                ></i>
              </a>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
