import React, { useEffect, useState } from "react";
import AppMenu from "../components/AppMenu";
import { Table } from "reactstrap";
import { getSiteError } from "../services/stateAPI";
import moment from "moment";
import { title } from "../config";
import ErrorList from "./ErrorList";


export default function ErrorSite() {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    reloadSite();
  }, []);
  const reloadSite = async (id) => {
    try {
      setLoading(true);
      const data = await getSiteError();
      setItems(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <div className="px-2" style={{backgroundColor:"#f8f9fa"}}>
      <h3 className="title m-0 p-2">Remote<span style={{color: '#ff6501'}}>Xs</span> Monitor | Error Sites</h3>
      <AppMenu title="Error Sites" />
      </div>
      <span style={{ float: "right", marginRight: "10px" }}>
        <b>Total:{items.length} &nbsp;&nbsp;</b>
      </span>
      <ErrorList items={items} loading={loading} />
    </div>
  );
}
