import React, { useEffect, useState } from "react";
import AppMenu from "../components/AppMenu";
import { title } from "../config";
import { getSiteData } from "../services/siteAPI";
import { Alert } from "reactstrap";
import { useNavigate } from "react-router";
import moment from "moment";
import { getLastMonthPerformance, getPerformanceData } from "../services/supportAPI";
import "../stylesheets/Dashboard.css"
const getTodos = (todosObj, name, performance, lastMonthPerformance) => {
  let textColor = "black";
  let icon;
  if (todosObj[0].count > 0) {
    textColor = "red";
    icon = "fa-thumbs-down"
  }
  else if (todosObj[0].count == 0) {
    textColor = "green";
    icon = "fa-thumbs-up"
  }
  else if (name?.ownerName?.toLocaleLowerCase.includes("zero")) {
    textColor = "green";
    icon = "NA";
  }

  return (
    <>
      <p style={{ color: textColor }} className=""><b>{name?.ownerName}</b>
        <i className={`fa-solid ${icon}`} style={{ marginLeft: '8px', color: textColor }} /><br /></p>
      {lastMonthPerformance && <p><b>Todos</b> - CM:({performance?.positive}/{performance?.positive + performance?.negative})   LM:({lastMonthPerformance?.positive}/{lastMonthPerformance?.positive + lastMonthPerformance?.negative}) </p>}
      <hr className="me-3" />
      <table className="" style={{ width: "100%" }}>
        <tbody>
          {
            todosObj?.map((e, index) => {
              let textPColor = 'blue';
              if (index === 0 && e?.count > 0) {
                textPColor = 'red';
              }
              else if (index === 0 && e?.count == 0) {
                textPColor = 'green';
              }
              if ((e?.title.includes('Memory 75') || e?.title.includes('30') || e?.title.includes('4 day') || e?.title.includes('60K')) && e?.count > 0) {
                textPColor = '#e36f27'; //
              }
              if ((e?.title.includes('45 Day') || e?.title.includes('80K')) && e?.count > 0) {
                textPColor = 'red';
              }
              if (e?.title.includes('45 Day') && e?.count > 0) {
                textPColor = 'red';
              }
              if (e.count === 0) {
                textPColor = 'green';
              }
              return (
                <tr key={index}>
                  <td>
                    <a style={{ color: textPColor }}
                      className="alert-link"
                      href={`${e?.endPoint}`}
                      rel="noreferrer"
                    >
                      {e?.title} ({e?.count})
                    </a>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </>
  );
};

const Dashboard = () => {
  const [loader, setLoader] = useState(true);
  const [performance, setPerformance] = useState([]);
  const [lastPerformance, setLastPerformance] = useState([]);
  const [redisKeys, setRedisKeys] = useState({});
  const [executionDateTime, setExecutionDateTime] = useState("");
  const [lastMonthRecords, setLastMonthRecords] = useState([]);
  const [expDateCounts, setExpDateCounts] = useState({
    expSevenDay: 0,
    expTwoDay: 0,
    expFifteenDay: 0,
  });
  const [memoryFull, setMemoryFull] = useState({
    mem80per: 0,
    mem75per: 0,
  });
  const [vuCount, setVuCount] = useState({
    vuProxy: 0,
    vuCms: 0,
    vuReport: 0,
    vuAdmin: 0,
    vuClient: 0,
    vu15Proxy: 0,
    vu15Cms: 0,
    vu15Admin: 0,
    vu15Report: 0,
    vu15Client: 0,
    vu45Proxy: 0,
    vu45Cms: 0,
    vu45Admin: 0,
    vu45Client: 0,
    vu45Report: 0,
  });
  let versionCount = {};
  const [login, setLogin] = useState({
    count24Hr: 0,
    lastHourCount: 0
  })
  const [dataUsage, setDataUsage] = useState({
    combineData: 0,
    downloadData: 0
  })


  const createCustomObject = (customObj, keyObj, extraKey, keyName) => {
    if (keyObj === "") {
      keyObj = "NA";
    }
    if (customObj[keyObj]) {
      customObj[keyObj].count += 1;
    } else {
      customObj[keyObj] = {
        count: 1,
        [extraKey]: keyName ? keyName : "NA",
      };
    }
  };

  const updateVersionCount = (versionObj, version, updatedDate, domain) => {
    if (version === "") {
      version = "NA";
    }
    if (versionObj[version]) {
      versionObj[version].count += 1;
    } else {
      versionObj[version] = {
        count: 1,
        date: updatedDate ? updatedDate : "NA",
      };
    }
  };
  const countFieldValues = ((value, countMap) => {
    if (value) {
      if (countMap.hasOwnProperty(value)) {
        countMap[value]++;
      } else {
        countMap[value] = 1;
      }
    }
  }
  );
  const getFreeSpace = (free, size) => {
    const percentageFull = ((size - free) / size) * 100;
    const roundValue = Math.round(percentageFull);
    return roundValue;
  };
  useEffect(() => {
    reloadSite();
  }, []);
  const getDate30DaysAgo = (days) => {
    const today = new Date();
    today.setDate(today.getDate() - days);
    return today;
  };
  const reloadSite = async () => {
    let records = await getPerformanceData();
    let lastMonth = await getLastMonthPerformance();
    setLastMonthRecords(lastMonth);
    let temp = await getSiteData();
    setExecutionDateTime(temp?.filter((e) => e.active === 1)[0]?.time);
    setLoader(false);
    let expDateCount = 0;
    let expTowDays = 0;
    let memoryLowCount = 0;
    let expFifteenDays = 0;
    let memory75PerCount = 0;
    let vuProxy = 0;
    let vuClient = 0;
    let vuCms = 0;
    let vuReport = 0;
    let vuAdmin = 0;
    let vu15Proxy = 0;
    let vu15Cms = 0;
    let vu15Report = 0;
    let vu15Admin = 0;
    let vu15Client = 0;
    let vu45Proxy = 0;
    let vu45Cms = 0;
    let vu45Report = 0;
    let vu45Admin = 0;
    let vu45Client = 0;
    let memory70PerCount = 0;
    let expFourDays = 0;
    const pm2ErrorDiffcount = {};
    const pm2OutDiffcount = {};
    const nodeVcount = {};
    let redisKeyCountObj = {}
    let redisKey100k = 0;
    let redisKey80k = 0;
    let redisKey60k = 0;
    let redisKey40k = 0;
    let cmsU45 = {
      negative: 0,
      positive: 0,
    }
    let reportU45 = {
      negative: 0,
      positive: 0,
    }
    let proxyU45 = {
      negative: 0,
      positive: 0,
    }
    let adminU45 = {
      negative: 0,
      positive: 0,
    }
    let clientU45 = {
      negative: 0,
      positive: 0,
    }
    let memory80 = {
      negative: 0,
      positive: 0,
    }
    let redis80 = {
      negative: 0,
      positive: 0,
    }
    let sslTwoDays = {
      negative: 0,
      positive: 0,
    }
    let cms45LM = {
      negative: 0,
      positive: 0,
    }
    let report45LM = {
      negative: 0,
      positive: 0,
    }
    let proxy45LM = {
      negative: 0,
      positive: 0,
    }
    let admin45LM = {
      negative: 0,
      positive: 0,
    }
    let client45LM = {
      negative: 0,
      positive: 0,
    }
    let memory80LM = {
      negative: 0,
      positive: 0,
    }
    let redis80LM = {
      negative: 0,
      positive: 0,
    }
    let sslTwoDaysLM = {
      negative: 0,
      positive: 0,
    }
    let loginCounts = {
      count24hr: 0,
      lastHr: 0,
    }
    let useageData = {
      combine: 0,
      download: 0,
    }
    records.map((e) => {
      if (e.name == "Update CMS > 45 Day" && e.point === -1) {
        cmsU45.negative++;
      }
      if (e.name == "Update CMS > 45 Day" && e.point === 1) {
        cmsU45.positive++;
      }
      if (e.name == "Update Proxy > 45 Day" && e.point === -1) {
        proxyU45.negative++;
      }
      if (e.name == "Update Proxy > 45 Day" && e.point === 1) {
        proxyU45.positive++;
      }
      if (e.name == "Update Client > 45 Day" && e.point === -1) {
        clientU45.negative++;
      }
      if (e.name == "Update Client > 45 Day" && e.point === 1) {
        clientU45.positive++;
      }
      if (e.name == "Update Admin > 45 Day" && e.point === -1) {
        adminU45.negative++;
      }
      if (e.name == "Update Admin > 45 Day" && e.point === 1) {
        adminU45.positive++;
      }
      if (e.name == "Update Report > 45 Day" && e.point === -1) {
        reportU45.negative++;
      }
      if (e.name == "Update Report > 45 Day" && e.point === 1) {
        reportU45.positive++;
      }
      if (e.name == "SSL Expiry in < 2 Days" && e.point === -1) {
        sslTwoDays.negative++;
      }
      if (e.name == "SSL Expiry in < 2 Days" && e.point === 1) {
        sslTwoDays.positive++;
      }
      // SSL Expiry in < 2 Days
      // 
      if (e.name == "Memory > 80% Full" && e.point === -1) {
        memory80.negative++;
      }
      if (e.name == "Memory > 80% Full" && e.point === 1) {
        memory80.positive++;
      }
      if (e.name === "Redis Keys > 80k" && e.point === -1) {
        redis80.negative++;
      }
      if (e.name === "Redis Keys > 80k" && e.point === 1) {
        redis80.positive++;
      }
      // if (e.name === "Redis Keys > 80k" && e.point===-1) {
      //   redis80.negative++;
      // }
      // if (e.name === "Redis Keys > 80k" && e.point===1) {
      //   redis80.positive++;
      // }
    });
    setPerformance({ cmsU45, proxyU45, adminU45, reportU45, clientU45, memory80, redis80, sslTwoDays })
    lastMonth.map((e) => {
      if (e.name == "Update CMS > 45 Day" && e.point === -1) {
        cms45LM.negative++;
      }
      if (e.name == "Update CMS > 45 Day" && e.point === 1) {
        cms45LM.positive++;
      }
      if (e.name == "Update Proxy > 45 Day" && e.point === -1) {
        proxy45LM.negative++;
      }
      if (e.name == "Update Proxy > 45 Day" && e.point === 1) {
        proxy45LM.positive++;
      }
      if (e.name == "Update Client > 45 Day" && e.point === -1) {
        client45LM.negative++;
      }
      if (e.name == "Update Client > 45 Day" && e.point === 1) {
        client45LM.positive++;
      }
      if (e.name == "Update Admin > 45 Day" && e.point === -1) {
        admin45LM.negative++;
      }
      if (e.name == "Update Admin > 45 Day" && e.point === 1) {
        admin45LM.positive++;
      }
      if (e.name == "Update Report > 45 Day" && e.point === -1) {
        report45LM.negative++;
      }
      if (e.name == "Update Report > 45 Day" && e.point === 1) {
        report45LM.positive++;
      }
      if (e.name == "SSL Expiry in < 2 Days" && e.point === -1) {
        sslTwoDaysLM.negative++;
      }
      if (e.name == "SSL Expiry in < 2 Days" && e.point === 1) {
        sslTwoDaysLM.positive++;
      }
      // SSL Expiry in < 2 Days
      // 
      if (e.name == "Memory > 80% Full" && e.point === -1) {
        memory80LM.negative++;
      }
      if (e.name == "Memory > 80% Full" && e.point === 1) {
        memory80LM.positive++;
      }
      if (e.name === "Redis Keys > 80k" && e.point === -1) {
        redis80LM.negative++;
      }
      if (e.name === "Redis Keys > 80k" && e.point === 1) {
        redis80LM.positive++;
      }
    });
    setLastPerformance({ cms45LM, proxy45LM, admin45LM, report45LM, client45LM, memory80LM, redis80LM, sslTwoDaysLM })
    temp.map(
      ({
        active,
        cms,
        status,
        version,
        exDate,
        email,
        free,
        size,
        exec_mode,
        cmsActive,
        newCmsVersion,
        adminVersion,
        customVersion,
        diffPM2ErrSize,
        node_version,
        date,
        name,
        redisKeyCount,
        diffPM2OutSize,
        reportVersion
      }) => {

        const thirtyDaysAgo = getDate30DaysAgo(30);
        const fifteenDaysAgo = getDate30DaysAgo(15);
        const fortyFiveDaysAgo = getDate30DaysAgo(45);
        if (active == 1) {
          updateVersionCount(versionCount, version, date);
          if (new Date(date) < new Date(thirtyDaysAgo)) {
            vuProxy++
          }
          if (new Date(date) < new Date(fortyFiveDaysAgo)) {
            vu45Proxy++
          }
          if (new Date(date) < new Date(fifteenDaysAgo)) {
            vu15Proxy++
          }
          if (moment(exDate) <= moment(new Date()).add(7, "d")) {
            expDateCount++;
          }
          if (moment(exDate) <= moment(new Date()).add(2, "d")) {
            expTowDays++;
          }
          if (moment(exDate) <= moment(new Date()).add(4, "d")) {
            expFourDays++;
          }
          if (moment(exDate) <= moment(new Date()).add(15, "d")) {
            expFifteenDays++;
          }
          if (getFreeSpace(free, size) > 80) {
            memoryLowCount++;
          }
          if (getFreeSpace(free, size) > 75) {
            memory75PerCount++;
          }
          if (getFreeSpace(free, size) > 70) {
            memory70PerCount++;
          }
        }
        if (redisKeyCount) {
          if (redisKeyCount > 100000) {
            redisKey100k++;
          }
          if (redisKeyCount > 80000) {
            redisKey80k++;
          }
          if (redisKeyCount > 60000) {
            redisKey60k++;
          }
          if (redisKeyCount > 40000) {
            redisKey40k++;
          }
          createCustomObject(redisKeyCountObj, redisKeyCount, "name", name);
        }
        if (cmsActive == 1) {
          if (new Date(customVersion?.date) < new Date(thirtyDaysAgo)) {
            vuCms++
          }
          if (new Date(newCmsVersion?.date) < new Date(thirtyDaysAgo)) {
            vuClient++
          }
          if (new Date(adminVersion?.date) < new Date(thirtyDaysAgo)) {
            vuAdmin++
          }
          if (new Date(reportVersion?.date) < new Date(thirtyDaysAgo)) {
            vuReport++
          }
          // 
          if (new Date(customVersion?.date) < new Date(fortyFiveDaysAgo)) {
            vu45Cms++
          }
          if (new Date(newCmsVersion?.date) < new Date(fortyFiveDaysAgo)) {
            vu45Client++
          }
          if (new Date(adminVersion?.date) < new Date(fortyFiveDaysAgo)) {
            vu45Admin++
          }
          if (new Date(reportVersion?.date) < new Date(fortyFiveDaysAgo)) {
            vu45Report++
          }
          //
          if (new Date(customVersion?.date) < new Date(fifteenDaysAgo)) {
            vu15Cms++
          }
          if (new Date(newCmsVersion?.date) < new Date(fifteenDaysAgo)) {
            vu15Client++
          }
          if (new Date(adminVersion?.date) < new Date(fifteenDaysAgo)) {
            vu15Admin++
          }
          if (new Date(reportVersion?.date) < new Date(fifteenDaysAgo)) {
            vu15Report++
          }
          if (reportVersion?.login?.count == 0) {
            loginCounts.count24hr++
          }
          if (reportVersion?.login?.lastHourCount == 0) {
            loginCounts.lastHr++
          }
          if (reportVersion?.data?.combineData == 0) {
            useageData.combine++
          }
          if (reportVersion?.data?.downloadData == 0) {
            useageData.download++
          }
        }
        if (diffPM2ErrSize && diffPM2ErrSize >= 0) {
          createCustomObject(pm2ErrorDiffcount, diffPM2ErrSize, "name", name);
          // countFieldValues(diffPM2ErrSize, pm2ErrorDiffcount);
        }
        if (diffPM2OutSize && diffPM2OutSize >= 0) {
          createCustomObject(pm2OutDiffcount, diffPM2OutSize, "name", name);
          // countFieldValues(diffPM2ErrSize, pm2ErrorDiffcount);
        }
        if (node_version) {
          countFieldValues(node_version, nodeVcount);
        }
      }
    );
    setLogin(loginCounts);
    setDataUsage(useageData);
    setExpDateCounts({
      ...expDateCount,
      expTwoDay: expTowDays,
      expSevenDay: expDateCount,
      expFifteenDay: expFifteenDays,
      expFourDays: expFourDays
    });
    setMemoryFull({
      ...memoryFull,
      mem80per: memoryLowCount,
      mem75per: memory75PerCount,
      mem70per: memory70PerCount
    });
    setVuCount({
      ...vuCount,
      vuProxy: vuProxy,
      vuCms: vuCms,
      vuAdmin: vuAdmin,
      vuReport: vuReport,
      vuClient: vuClient,
      vu15Proxy: vu15Proxy,
      vu15Cms: vu15Cms,
      vu15Admin: vu15Admin,
      vu15Report: vu15Report,
      vu15Client: vu15Client,
      vu45Proxy: vu45Proxy,
      vu45Cms: vu45Cms,
      vu45Report: vu45Report,
      vu45Admin: vu45Admin,
      vu45Client: vu45Client
    });
    setRedisKeys({
      ...redisKeys,
      redisKey80k: redisKey80k,
      redisKey60k: redisKey60k,
      redisKey40k: redisKey40k,
      redisKey100k: redisKey100k
    });

  };
  return (
    <div>
      <div className="px-2" style={{ backgroundColor: "#f8f9fa" }}>
      <h3 className="dashboard-title m-0 p-2">Remote<span style={{color: '#ff6501'}}>Xs</span> Monitor | Todo Dashboard</h3>
      <AppMenu />
        <p
          style={{
            // textAlign: "center",
            display: "flex",
            // justifyContent: "center",
            alignItems: "center",
            margin: "0 auto",
            height: "50px",
            lineHeight: "50px",
            fontSize: "16px",
            fontWeight: "500",
            color: "#333",
            backgroundColor: "#f8f9fa",
            borderRadius: "1px",
            padding: "0 10px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
          <b>Date/Time: {executionDateTime &&
            moment(executionDateTime)
              .utcOffset("Asia/Kolkata")
              .format("DD MMM, YYYY - hh:mm A ")}
              </b>
        </p>
      </div>

      {loader ?
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "75vh" }}>
          <div className="spinner-border">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
        : <div>
          <div className="row m-lg-1">
            <div className="col-md-3 col-sm-12">
              <div className="card-container">
                <Alert color="primary" className="p-3 pe-0 ">
                  <div style={{ height: "210px", overflow: "auto" }}>
                    {getTodos(
                      [
                        { title: "Update Proxy > 45 Day", count: vuCount.vu45Proxy, endPoint: "/todo/vu-proxy/45" },
                        { title: "Update Proxy > 30 Day", count: vuCount.vuProxy, endPoint: "/todo/vu-proxy/30" },
                        { title: "Update Proxy > 15 Day", count: vuCount.vu15Proxy, endPoint: "/todo/vu-proxy/15" },
                      ],
                      { ownerName: "Dhaval Gohel" },
                      performance.proxyU45,
                      lastPerformance.proxy45LM
                    )}
                  </div>
                </Alert>
              </div>
            </div>
            <div className="col-md-3 col-sm-12">
            <div className="card-container">
              <Alert color="primary" className="p-3 pe-0">
                <div style={{ height: "210px", overflow: "auto" }}>
                  {getTodos(
                    [{ title: "Update CMS > 45 days", count: vuCount.vu45Cms, endPoint: "/todo/vu-cms/45" },
                    { title: "Update CMS > 30 Days", count: vuCount.vuCms, endPoint: "/todo/vu-cms/30" },
                    { title: "Update CMS > 15 days", count: vuCount.vu15Cms, endPoint: "/todo/vu-cms/15" }],
                    { ownerName: "Bhagya | Hiral" }, performance.cmsU45, lastPerformance.cms45LM)}
                </div>
              </Alert>
              </div>
            </div>
            <div className="col-md-3 col-sm-12">
            <div className="card-container">

              <Alert color="primary" className="p-3 pe-0">
                <div style={{ height: "210px", overflow: "auto" }}>
                  {getTodos(
                    [
                      { title: "Update Client > 45 Day", count: vuCount.vu45Client, endPoint: "/todo/vu-client/45" },
                      { title: "Update Client > 30 Day", count: vuCount.vuClient, endPoint: "/todo/vu-client/30" },
                      { title: "Update Client > 15 Day", count: vuCount.vu15Client, endPoint: "/todo/vu-client/15" },
                    ], { ownerName: "Krunal | Nehal" }, { negative: performance.clientU45.negative + performance.clientU45.negative, positive: performance.clientU45.positive + performance.clientU45.positive }, { negative: lastPerformance.client45LM.negative + lastPerformance.client45LM.negative, positive: lastPerformance.client45LM.positive + lastPerformance.client45LM.positive })}
                </div>
              </Alert>
            </div>
            </div>
            <div className="col-md-3 col-sm-12">
            <div className="card-container">

              <Alert color="primary" className="p-3 pe-0">
                <div style={{ height: "210px", overflow: "auto" }}>
                  {getTodos(
                    [
                      { title: "Update Admin > 45 Day", count: vuCount.vu45Admin, endPoint: "/todo/vu-admin/45" },
                      { title: "Update Admin > 30 Day", count: vuCount.vuAdmin, endPoint: "/todo/vu-admin/30" },
                      { title: "Update Admin > 15 Day", count: vuCount.vu15Admin, endPoint: "/todo/vu-admin/15" },
                    ], { ownerName: "Nehal" }, { negative: performance.adminU45.negative + performance.adminU45.negative, positive: performance.adminU45.positive + performance.adminU45.positive }, { negative: lastPerformance.admin45LM.negative + lastPerformance.admin45LM.negative, positive: lastPerformance.admin45LM.positive + lastPerformance.admin45LM.positive })}
                </div>
              </Alert>
            </div>
            </div>
            <div className="col-md-3 col-sm-12">
            <div className="card-container">
              <Alert color="primary" className="p-3 pe-0">
                <div style={{ height: "210px", overflow: "auto" }}>
                  {getTodos(
                    [
                      { title: "Update Report > 45 Day", count: vuCount.vu45Report, endPoint: "/todo/vu-report/45" },
                      { title: "Update Report > 30 Day", count: vuCount.vuReport, endPoint: "/todo/vu-report/30" },
                      { title: "Update Report > 15 Day", count: vuCount.vu15Report, endPoint: "/todo/vu-report/15" },
                    ], { ownerName: "Bhagya | Nehal" }, { negative: performance.reportU45.negative + performance.reportU45.negative, positive: performance.reportU45.positive + performance.reportU45.positive }, { negative: lastPerformance.report45LM.negative + lastPerformance.report45LM.negative, positive: lastPerformance.report45LM.positive + lastPerformance.report45LM.positive })}
                </div>
              </Alert>
            </div>
            </div>
            <div className="col-md-3 col-sm-12">
            <div className="card-container">

              <Alert color="primary" className="p-3 pe-0">
                <div style={{ height: "210px", overflow: "auto" }}>
                  {getTodos([{ title: "Memory 80% full", count: memoryFull?.mem80per, endPoint: "/sites/free/80" }, { title: "Memory 75% full", count: memoryFull?.mem75per, endPoint: "/sites/free/75" }, { title: "Memory 70% full", count: memoryFull?.mem70per, endPoint: "/sites/free/70" }], { ownerName: "Hiral | Nehal" }, performance.memory80, lastPerformance?.memory80LM)}
                </div>
              </Alert>
            </div>
            </div>
            <div className="col-md-3 col-sm-12">
            <div className="card-container">
              <Alert color="primary" className="p-3 pe-0">
                <div style={{ height: "210px", overflow: "auto" }}>
                  {getTodos([{ title: "SSL Expiry in 2 days", count: expDateCounts?.expTwoDay, endPoint: "/sites/expiry/2" }, { title: "SSL Expiry in 4 days", count: expDateCounts?.expFourDays, endPoint: "/sites/expiry/4" }, { title: "SSL Expiry in 7 days", count: expDateCounts?.expSevenDay, endPoint: "/sites/expiry/7" }, { title: "SSL Expiry in 15 days", count: expDateCounts?.expFifteenDay, endPoint: "/sites/expiry/15" }], { ownerName: "Hitanshi | Hiral" }, performance.sslTwoDays, lastPerformance.sslTwoDaysLM)}
                </div>
              </Alert>
            </div>
            </div>
            <div className="col-md-3 col-sm-12">
            <div className="card-container">
              <Alert color="primary" className="p-3 pe-0">
                <div style={{ height: "210px", overflowX: "auto", overflowY: "auto" }}>
                  {getTodos([
                    { title: "Redis Key > 80K", count: redisKeys?.redisKey80k, endPoint: "/todo/redis/80000" },
                    { title: "Redis Key > 60K", count: redisKeys?.redisKey60k, endPoint: "/todo/redis/60000" },
                    { title: "Redis Key > 40K", count: redisKeys?.redisKey40k, endPoint: "/todo/redis/40000" }],
                    { ownerName: "Yash Joshi's" }, performance.redis80, lastPerformance.redis80LM
                  )}
                </div>
              </Alert>
            </div>
            </div>
            <div className="col-md-3 col-sm-12">
            <div className="card-container">
              <Alert color="primary" className="p-3 pe-0">
                <div style={{ height: "210px", overflowX: "auto", overflowY: "auto" }}>
                  {/* { title: "last Hours", count: login?.lastHr, endPoint: "/todo/lastHourCount/0" }, */}
                  {getTodos([
                    { title: "Last 24 Hours", count: login?.count24hr, endPoint: "/todo/count/0" },

                  ],
                    { ownerName: "Zero Login count" }
                  )}
                </div>
              </Alert>
            </div>
            </div>
            <div className="col-md-3 col-sm-12">
            <div className="card-container">
              <Alert color="primary" className="p-3 pe-0">
                <div style={{ height: "210px", overflowX: "auto", overflowY: "auto" }}>
                  {getTodos([
                    { title: "Combine", count: dataUsage?.combine, endPoint: "/todo/combineData/0" },
                    { title: "Download", count: dataUsage?.download, endPoint: "/todo/downloadData/0" }
                  ],
                    { ownerName: "Zero Data Usage" })}
                </div>
              </Alert>
            </div>
            </div>
          </div>
          <div className="row m-lg-1">
          </div>

          <div className="row m-lg-1">

          </div>
        </div>
      }
    </div>
  );
};
export default Dashboard;
