import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { deleteSite, getSiteData } from "../services/siteAPI";
import AppMenu from "../components/AppMenu";
import { Table } from "reactstrap";
import moment from "moment";
import { title } from "../config";
import TodoListTable from "./TodoListTable";
import { Link } from "react-router-dom";
import "../stylesheets/todo.css"
// import { siteActiveInactive } from "../services/constant";

export function SiteTodo() {
  const navigate = useNavigate();
  const { field, value } = useParams();
  const [pageTitle, setPageTitle] = useState();
  const [items, setItems] = useState([]);
  const [allSites, setAllSites] = useState([]);
  const [filter, setFilter] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);

  const getAddButton = (filter, navigate) => {
    if (!filter) {
      return (
        <button
          className="fa-sharp fa-plus btn btn-outline-primary"
          onClick={() => {
            navigate("/site/add");
          }}
          variant="contained"
        > Add </button>
      );
    }
  };
  const getDate30DaysAgo = (days) => {
    const today = new Date();
    today.setDate(today.getDate() - days);
    return today;
  };
  const getFreeSpace = (free, size) => {
    const percentageFull = ((size - free) / size) * 100;
    const roundValue = Math.round(percentageFull);
    return roundValue;
  };

  const reloadSite = async () => {
    let isFilter = false;
    let records = await getSiteData();
    if (value) {
      isFilter = true;
      setFilter(true);
    }
    if (isFilter && field === "vu-client") {
      const thirtyDaysAgo = getDate30DaysAgo(value);
      records = records.filter((e) =>
        e.cmsActive == 1 && new Date(e.newCmsVersion?.date) < thirtyDaysAgo
      );
      setPageTitle(`Client version older than ${value} Days`);
    }
    if (isFilter && field === "vu-proxy") {
      const thirtyDaysAgo = getDate30DaysAgo(value);
      records = records.filter((e) =>
        e.active == 1 && new Date(e?.date) < thirtyDaysAgo
      );
      setPageTitle(`Proxy version older than ${value} Days`);
    }
    if (isFilter && field === "vu-admin") {
      const thirtyDaysAgo = getDate30DaysAgo(value);
      records = records.filter((e) =>
        e.cmsActive == 1 && new Date(e?.adminVersion?.date) < thirtyDaysAgo
      );
      setPageTitle(`Admin version older than ${value} Days`);
    }
    if (isFilter && field === "vu-report") {
      const thirtyDaysAgo = getDate30DaysAgo(value);
      records = records.filter((e) =>
        e.cmsActive == 1 && new Date(e?.reportVersion?.date) < thirtyDaysAgo
      );
      setPageTitle(`Report version older than ${value} Days`);
    }
    if (isFilter && field === "actionType") {
      records = records.filter((e) =>
        e.cmsActive == 1 && e.action?.actionType===value
      );
      setPageTitle(`CMS Update`);
    }
    if (isFilter && field === "vu-cms") {
      const thirtyDaysAgo = getDate30DaysAgo(value);
      records = records.filter((e) =>
        e.cmsActive == 1 && new Date(e?.customVersion?.date) < thirtyDaysAgo
      );
      setPageTitle(`CMS version older than ${value} Days`);
    }
    if (isFilter && field === "redis") {
      records = records.filter((e) =>
        e.cmsActive == 1 && e?.redisKeyCount > value
      );
      setPageTitle(`Redis key greater than ${value}`);
    }
    if (isFilter && field === "combineData") {
      records = records.filter((e) =>
        e.cmsActive == 1 && e?.reportVersion?.data?.combineData == value
      );
      setPageTitle(`Combine ${value}MB Data Usage`);
    }
    if (isFilter && field === "downloadData") {
      records = records.filter((e) =>
        e.cmsActive == 1 && e?.reportVersion?.data?.downloadData == value
      );
      setPageTitle(`Download ${value}MB Data`);
    }
    if (isFilter && field === "count") {
      records = records.filter((e) =>
        e.cmsActive == 1 && e?.reportVersion?.login?.count == value
      );
      setPageTitle(`Last 24 Hours Login ${value} Count`);
    }
    if (isFilter && field === "lastHourCount") {
      records = records.filter((e) =>
        e.cmsActive == 1 && e?.reportVersion?.login?.lastHourCount == value
      );
      setPageTitle(`Last Hr. Login Count : ${value}`);
    }
    setAllSites(records);
    setLoading(false);
    setItems(records);
  };
  const doDelete = async (_id) => {
    await deleteSite(_id);
    reloadSite();
  };
  const doUpgrade = (link) => {
    const upgradeUrl = `https://core.${link}/check-update`;
    return upgradeUrl;
  };
  const doHistory = (id) => {
    navigate(`/state/${id}`);
  };
  const doEdit = (id) => {
    navigate(`/site/edit/${id}`);
  };
  const onSearchChange = (e) => {
    const text = e.target.value.toLowerCase();
    const searchResult = allSites.filter((record) => {
      if (record.name.toLowerCase().startsWith(text)) {
        return true;
      }
      if (record.domain.toLowerCase().startsWith(text)) {
        return true;
      }
    });
    setLoading(false);
    setSearchText(e.target.value);
    setItems(searchResult);
  };
  const getFileNameFromURL = () => {
    const urlPath = window.location.pathname; 
    return urlPath.replace(/\//g, '-').substring(1);
  };
  const doDownload = () => {

    const convertToCSV = (items) => {
      const csvRows = [];
  
      csvRows.push('domain');
  
      items.forEach(item => {
        csvRows.push(item.domain || ''); // Handle cases where 'domain' might be undefined
      });
  
      return csvRows.join('\n');
    };
    const downloadCSV = (csvData,fileName) => {
      const blob = new Blob([csvData], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download =  `${fileName}.csv`; 
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
  
    const csvData = convertToCSV(items);
    const fileName = getFileNameFromURL();
    downloadCSV(csvData,fileName);
  };
  useEffect(() => {
    reloadSite();
    setPageTitle("Sites");
  }, [field, value]);
  return (
    <div>
      <div className="px-2" style={{backgroundColor:"#f8f9fa"}}>
      <h3 className="title m-0 p-2">Remote<span style={{color: '#ff6501'}}>Xs</span> Monitor | {pageTitle}</h3>
        <AppMenu />
      </div>
      <div className="px-2">
        <div className="m-0 px-2">
          <Link to={"/todo/vu-proxy/30"}>VU Proxy </Link> &nbsp;|&nbsp;
          <Link to={"/todo/vu-admin/30"}>VU Admin </Link> &nbsp;|&nbsp;
          <Link to={"/todo/vu-report/30"}>VU Report </Link> &nbsp;|&nbsp;
          <Link to={"/todo/vu-client/30"}>VU Client</Link>&nbsp; | &nbsp;
          <Link to={"/todo/vu-cms/30"}>VU CMS</Link>&nbsp; | &nbsp;
          <Link to={"/todo/actionType/cms-update"}>CMS Update</Link>&nbsp; | &nbsp;
          <Link to={"/todo/actionType/others"}>Others</Link>
        </div>
      </div>
      <div className="p-0 px-2 m-0">
        <hr className="p-0 my-2" />
        <div className="px-2">
          <input
            value={searchText}
            onChange={onSearchChange}
            placeholder="Search Site Name"
            type="search"
            className="rounded-4"
          />
          <span className="headerMargin ms-1">{getAddButton(filter, navigate)}</span>
          <span style={{ float: "right", marginRight: "10px" }} >
          {/* <span style={{ float: "right", marginRight: "10px" }}> */}
          <i className="fa-solid fa-download pr-5 btn" onClick={doDownload}/> &nbsp;
            <b>Total:{items.length} &nbsp;&nbsp;</b>
          </span>
        </div>
        <hr className="p-0 my-2" />
      </div>
      {
        loading ?
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "75vh" }}>
            <div className="spinner-border">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div> :
          items?.length > 0 ? (
            <TodoListTable
              items={items}
              doDelete={doDelete}
              doEdit={doEdit}
              doHistory={doHistory}
              doUpgrade={doUpgrade}
            />
          )
            :
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "75vh" }}>
              {/* <div className="spinner-border"> */}
              <span className="">No records found...</span>
              {/* </div> */}
            </div>}
    </div>
  );
}


