import React, { useEffect, useState } from 'react'
import AppMenu from '../components/AppMenu'
import { getUserDetails } from '../services/userStorage';
import { useLocation, useNavigate, useParams } from 'react-router';
import { getReleaseById } from '../services/releaseAPI';
import { addReleaseRecords, getReleaseRecordsById, updateReleaseRecord } from '../services/releaseRecordAPI';
import { title } from '../config';
import { Button, FormGroup, Input, Label } from 'reactstrap';

const AddReleaseRecord = () => {
    const navigate = useNavigate();
    const userDetails = getUserDetails();
    const { recordId } = useParams();
    const { state } = useLocation();
    const [formInput, setFormInput] = useState({});
    const [releaseName, setReleaseName] = useState("");
    const [features, setFeatures] = useState([]);
    const [buttonLabel, setButtonLabel] = useState("Submit");
    const [allSelected, setAllSelected] = useState(false);
    useEffect(() => {
        if (recordId) {
            loadRelease(recordId);
        } else {
            initializeFields();
        }
    }, [recordId]);
    const initializeFields = async () => {
        const release = await getReleaseById(state?.release?._id);
        const initialFeatures = await (state?.features == true ? release?.features : release?.features)?.map(feature => ({
            feature: feature,
            result: false
        }));
        setFeatures(initialFeatures);
        setFormInput({
            releaseName: release.releaseName || "",
            createdBy: userDetails.name || "",
            email: userDetails.email || "",
            createdAt: "",
        });
        setReleaseName(release.releaseName || "");
    };

    const loadRelease = async (id) => {
        try {
            const record = await getReleaseRecordsById(id);
            setFormInput(record);
            setReleaseName(record.record.releaseName || "");
            const initialFeatures = record.record.features.map(feature => ({
                feature: feature.feature || "",
                result: feature.result || false
            }));
            setFeatures(initialFeatures);
            setButtonLabel("Update");
        } catch (error) {
            console.error("Error loading release record:", error);
        }
    };

    const handleFeatureChange = (index, checked) => {
        const updatedFeatures = features.map((feature, i) =>
            i === index ? { ...feature, result: checked } : feature
        );
        setFeatures(updatedFeatures);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const currentDateTime = new Date().toISOString();
        const newItem = {
            ...formInput,
            lastUpdated: currentDateTime,
            createdAt: formInput.createdAt || currentDateTime,
            features: features,
            createdBy: userDetails.name,
            updateBy: userDetails.name,
            email: userDetails.email,
            releaseId: state?.release?._id,
            releaseName: releaseName,
        };
        const allFeatureCompleted = newItem.features.every(feature => feature.result === true);
        const doSubmit = async () => {
            try {
                if (state?.id) {
                    await updateReleaseRecord(newItem)
                } else {
                    await addReleaseRecords(newItem)
                }
                navigate("/release");
            } catch (error) {
                console.error("Error submitting the release record:", error);
            }
        };

        if (!allFeatureCompleted) {
            const userConfirmed = window.confirm("Not all features are completed. Are you sure you want to continue?");
            if (userConfirmed) {
                await doSubmit();
            }
        } else {
            await doSubmit();
        }
    };

    const handleSelectAll = () => {
        const newAllSelected = !allSelected;
        const updatedFeatures = features.map(feature => ({ ...feature, result: newAllSelected }));
        setFeatures(updatedFeatures);
        setAllSelected(newAllSelected);
    };
    return (
        <div>
            <div className="px-2" style={{backgroundColor:"#f8f9fa"}}>
                <h3 className="m-0 p-2">{title} | {buttonLabel} Release Record</h3>
                <AppMenu title="Add Release" />
            </div>
            <div className="container p-3">
            <h4 className="m-0 p-2">{releaseName}</h4>
            <div className="card p-4 shadow-sm">
                {features.map((featureObj, index) => (
                    <FormGroup key={index} className="d-flex align-items-center mb-2">
                        <Input
                            type="checkbox"
                            name={`feature_${index}`}
                            id={`feature_${index}`}
                            checked={featureObj.result}
                            onChange={(e) => handleFeatureChange(index, e.target.checked)}
                            className='me-2'
                        />
                        <Label for={`feature_${index}`} className="mb-0">
                            <strong>{featureObj.feature}</strong>
                        </Label>
                    </FormGroup>
                ))}
                <FormGroup check className="mb-3">
                    <Label check>
                        <Input
                            type="checkbox"
                            id="selectAllCheckbox"
                            checked={allSelected}
                            onChange={handleSelectAll}
                            style={{ boxShadow: 'none' }}
                        />
                        <strong className="ms-2">{allSelected ? "Deselect All" : "Select All"}</strong>
                    </Label>
                </FormGroup>
                <div className="row justify-content-center">
                    <div className="col-md-4 col-sm-6 mb-2">
                        <Button className="w-100" type="submit" color="primary" onClick={handleSubmit}>
                            {buttonLabel}
                        </Button>
                    </div>
                    <div className="col-md-4 col-sm-6 mb-2">
                        <Button className="w-100" color="secondary" onClick={() => navigate("/release")}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
}

export default AddReleaseRecord