import React, { useEffect, useState } from 'react'
import AppMenu from '../components/AppMenu';
import { getUserDetails } from '../services/userStorage';
import { useParams } from 'react-router';
import { addReleaseData, getReleaseById, updateReleaseData } from '../services/releaseAPI';
import { useNavigate } from "react-router";
import AddReleaseRecord from './AddReleaseRecord';
import { getReleaseRecordsByReleaseId, updateReleaseRecord } from '../services/releaseRecordAPI';

const AddRelease = () => {
    const userDetails = getUserDetails();
    const { recordId } = useParams();
    const [featureValue, setFeatureValue] = useState();
    const [feature, setFeatures] = useState();
    const [release, setRelease] = useState({
        releaseName: "",
        version: "",
        createdAt: "",
        updatedAt: "",
        updatedBy: {
            id: userDetails?._id || "",
            name: userDetails?.name | "",
            email: userDetails?.email || ""
        },
        features: [""],
        date: ""
    });
    const navigate = useNavigate();
    const [buttonLabel, setButtonLabel] = useState(`Add${" Release"}`);
    const loadRelease = async (id) => {
        try {
            const record = await getReleaseById(id);
            setRelease(record);
            setButtonLabel("Update");
        } catch (error) {
            console.error("Failed to load release data:", error);
        }
    };
    useEffect(() => {
        if (recordId) {
            loadRelease(recordId);
        }
    }, [recordId]);
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!release.releaseName.trim()) {
            return;
        }
        const now = new Date().toISOString();
        const newItem = {
            ...release,
            updatedAt: now,
            updatedBy: {
                id: userDetails?._id,
                name: userDetails?.name,
                email: userDetails?.email,
            },
            ...(release._id ? {} : {
                createdAt: now, createdBy: {
                    id: userDetails?._id,
                    name: userDetails?.name,
                    email: userDetails?.email,
                },
            }),
        };
        try {
            if (recordId) {
                const record = await getReleaseRecordsByReleaseId(recordId)
                if (record.length > 0) {
                    const existingFeatures = record[0]?.record?.features;
                    const featureList = Array.isArray(feature) ? feature : [];
                    const existingFeatureMap = new Map(existingFeatures.map(f => [f.feature, f.result]));
                    const updatedFeatures = existingFeatures
                        .filter(f => feature.includes(f.feature))
                        .map(f => ({
                            feature: f.feature,
                            result: f.result
                        }));
                    const newFeatures = featureList
                        .filter(f => !existingFeatureMap.has(f))
                        .map(f => ({
                            feature: f,
                            result: false
                        }));
                    const finalFeatures = [
                        ...updatedFeatures,
                        ...newFeatures
                    ];
                    const item = {
                        ...record[0],
                        features: finalFeatures,
                        _id: record[0]._id
                    };
                    await updateReleaseRecord(item)
                    await updateReleaseData(newItem);
                } else {
                    await updateReleaseData(newItem);
                }
            } else {
                await addReleaseData(newItem);
            }
            window.history.back();
        } catch (error) {
            console.error("Failed to submit form:", error);
        }
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setRelease({ ...release, [name]: value });
        const newfeatures = [...release.features];
        const valueFeature = ({ ...release, features: newfeatures });
        setFeatureValue({ feature: valueFeature, result: false })
    };
    const handleFeatureChange = (e, index) => {
        const newfeatures = [...release.features];
        newfeatures[index] = e.target.value;
        setRelease({ ...release, features: newfeatures });
        setFeatures(newfeatures);
    };
    const addFeature = () => {
        setRelease({ ...release, features: [...release.features, ""] });
    };
    const removeFeature = (index) => {
        const newfeatures = release.features.filter((_, i) => i !== index);
        setRelease({ ...release, features: newfeatures });
        setFeatures(newfeatures);
    };
    return (
        <div>
            <div className="px-2" style={{backgroundColor:"#f8f9fa"}}>
                <h3 className="m-0 p-2"> Release</h3>
                <AppMenu />
            </div>
            <div className="container p-3">
                <div className="card p-4 shadow-sm">
                    <form onSubmit={handleSubmit}>
                        <div className="row g-4">
                            {/* Fields other than Features */}
                            <div className="col-md-6 col-sm-12">
                                <div className="row">
                                    {/* Release Name */}
                                    <div className="col-md-12 mb-3">
                                        <strong><label htmlFor="releaseName" className="form-label">Planned Release Name</label></strong>
                                        <input
                                            type="text"
                                            id="releaseName"
                                            name="releaseName"
                                            value={release.releaseName}
                                            onChange={handleChange}
                                            className="form-control"
                                            required
                                        />
                                    </div>

                                    {/* Planned Release Version */}
                                    <div className="col-md-12 mb-3">
                                        <strong><label htmlFor="version" className="form-label">Planned Release Version</label></strong>
                                        <input
                                            type="text"
                                            id="version"
                                            name="version"
                                            value={release.version}
                                            onChange={handleChange}
                                            className="form-control"
                                            required
                                        />
                                    </div>

                                    {/* Date */}
                                    <div className="col-md-12 mb-3">
                                        <strong><label htmlFor="dueDate" className="form-label">Date</label></strong>
                                        <input
                                            type="date"
                                            id="dueDate"
                                            name="date"
                                            value={release.date}
                                            onChange={handleChange}
                                            className="form-control"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* Features */}
                            <div className="col-md-6 col-sm-12">
                                <strong><label className="form-label">Features</label></strong>
                                {release?.features?.map((feature, index) => (
                                    <div className="input-group mb-2" key={index}>
                                        <input
                                            type="text"
                                            value={feature}
                                            onChange={(e) => handleFeatureChange(e, index)}
                                            className="form-control"
                                            required
                                        />
                                        {release.features.length > 1 && (
                                            <button
                                                type="button"
                                                className="btn btn-outline-danger ms-2"
                                                onClick={() => removeFeature(index)}
                                            >
                                                Remove
                                            </button>
                                        )}
                                    </div>
                                ))}
                                <button
                                    type="button"
                                    className="btn btn-primary mt-2"
                                    onClick={addFeature}
                                >
                                    Add More Feature
                                </button>
                            </div>
                        </div>

                        {/* Submit and Cancel Buttons */}
                        <div className="row justify-content-center mt-3">
                            <div className="col-md-4 col-sm-6 mb-2">
                                <button type="submit" className="btn btn-success w-100">
                                    {buttonLabel}
                                </button>
                            </div>
                            <div className="col-md-4 col-sm-6 mb-2">
                                <button
                                    type="button"
                                    className="btn btn-secondary w-100"
                                    onClick={() => navigate("/release")}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AddRelease;