import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router";
import { addProcessRecords, getProcessRecordsById, updateProcessRecord } from "../services/processRecordAPI";
import AppMenu from "../components/AppMenu";
import { title } from "../config";
import { Button, FormGroup, Label, Input } from "reactstrap";
import { getAllProcess, getProcessById } from '../services/processAPI';
import { getUserDetails } from "../services/userStorage";
import { addQARecords, getQARecordsById, updateQARecord } from "../services/qaRecordAPI";

export default function AddProcessRecords() {
    const navigate = useNavigate();
    const userDetails = getUserDetails();
    const { recordId } = useParams();
    const { state } = useLocation();
    const [formInput, setFormInput] = useState({});
    const [processName, setProcessName] = useState("");
    const [siteName, setSiteName] = useState("");
    const [steps, setSteps] = useState([]);
    const [buttonLabel, setButtonLabel] = useState("Submit");
    const [allSelected, setAllSelected] = useState(false);

    useEffect(() => {
        if (recordId) {
            loadProcess(recordId);
        } else {
            initializeFields();
        }
    }, [recordId]);

    const initializeFields = async () => {
        const process = await getProcessById(state?.process?._id);
        const initialSteps = await (state?.qaRecords == true ? process?.qaSteps : process?.steps)?.map(step => ({
            step: step,
            result: false
        }));
        setSteps(initialSteps);
        setFormInput({
            processName: process.processName || "",
            createdBy: userDetails.name || "",
            email: userDetails.email || "",
            createdAt: "",
            siteName: state?.siteName || process.siteName || ""
        });
        setProcessName(process.processName || "");
        setSiteName(state?.siteName || process.siteName || "");
    };

    const loadProcess = async (id) => {
        try {
            const record = state?.qaRecords == true ? await getQARecordsById(id) : await getProcessRecordsById(id);
            setFormInput(record);
            setProcessName(record.record.processName || "");
            setSiteName(record.record.siteName || "");
            const initialSteps = record.record.steps.map(step => ({
                step: step.step || "",
                result: step.result || false
            }));
            setSteps(initialSteps);
            setButtonLabel("Update");
        } catch (error) {
            console.error("Error loading process record:", error);
        }
    };

    const handleStepChange = (index, checked) => {
        const updatedSteps = steps.map((step, i) =>
            i === index ? { ...step, result: checked } : step
        );
        setSteps(updatedSteps);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const currentDateTime = new Date().toISOString();
        const newItem = {
            ...formInput,
            lastUpdated: currentDateTime,
            createdAt: formInput.createdAt || currentDateTime,
            siteName: siteName,
            steps: steps,
            createdBy: userDetails.name,
            updateBy: userDetails.name,
            email: userDetails.email,
            processId: state?.process?._id,
            processName: processName,
        };

        const allStepsCompleted = newItem.steps.every(step => step.result === true);
        const doSubmit = async () => {
            try {
                if (state?.id) {
                    state?.qaRecords == true ? await updateQARecord(newItem) : await updateProcessRecord(newItem)
                } else {
                    state?.qaRecords == true ? await addQARecords(newItem) : await addProcessRecords(newItem)
                }
                navigate("/process");
            } catch (error) {
                console.error("Error submitting the process record:", error);
            }
        };

        if (!allStepsCompleted) {
            const userConfirmed = window.confirm("Not all steps are completed. Are you sure you want to continue?");
            if (userConfirmed) {
                await doSubmit();
            }
        } else {
            await doSubmit();
        }
    };

    const handleSelectAll = () => {
        const newAllSelected = !allSelected;
        const updatedSteps = steps.map(step => ({ ...step, result: newAllSelected }));
        setSteps(updatedSteps);
        setAllSelected(newAllSelected);
    };
    return (
        <div>
            <div className="px-2" style={{backgroundColor:"#f8f9fa"}}>
                <h3 className="m-0 p-2">{title} | {buttonLabel} {state?.qaRecords == true ? 'QA ' : "Process "} Record</h3>
                <AppMenu title="Add Process" />
            </div>
            <h4 className="m-0 p-2">{processName}</h4>
            <div className="p-0 m-0 px-2">
                <div className="card p-3">
                    <div className="col-3">
                        <FormGroup>
                            <Label for="siteName">Site Name</Label>
                            <Input
                                type="text"
                                name="siteName"
                                id="siteName"
                                value={siteName}
                                onChange={(e) => setSiteName(e.target.value)}
                                className='m-1'
                            />
                        </FormGroup>
                    </div>
                    {steps.map((stepObj, index) => (
                        <FormGroup key={index}>
                            <Input
                                type="checkbox"
                                name={`step_${index}`}
                                id={`step_${index}`}
                                checked={stepObj.result}
                                onChange={(e) => handleStepChange(index, e.target.checked)}
                                className='m-1'
                            />
                            <Label for={`step_${index}`} className="ms-2">
                                <strong>{stepObj.step}</strong>
                            </Label>
                        </FormGroup>
                    ))}
                        <FormGroup check className="mb-3">
                            <Label check>
                                <Input
                                    type="checkbox"
                                    id="selectAllCheckbox"
                                    checked={allSelected}
                                    onChange={handleSelectAll}
                                    style={{ boxShadow: 'none' }}
                                />
                                <strong>{allSelected ? " Deselect All" : " Select All"}</strong>
                            </Label>
                        </FormGroup>
                    <div className="row justify-content-center">
                        <Button className="col-md-2 col-sm-6" type="submit" color="primary" onClick={handleSubmit}>{buttonLabel}</Button>
                        &nbsp;&nbsp;
                        <Button className="col-md-2 col-sm-6" color="secondary" onClick={() => navigate("/process")}>Cancel</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
